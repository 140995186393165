<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Requisition Date</th>
          <th>Requisition No</th>
          <th>Project</th>
          <th>Department</th>
          <th>Total</th>
          <th>Current Step</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in requisitionList.data" :key="i">
          <td>{{ item.date }}</td>
          <td>{{ item.requisition_number }}</td>
          <td>{{ item.project.name }}</td>
          <td>{{ item.department.name }}</td>
          <td>{{ item.total_amount }}</td>
          <td>{{ item.current_step_no }}</td>
          <td>{{ getStatus(item.status) }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                :disabled="saveButtonLoader"
                @click="goToReview(item.id, true)"
                style="cursor: pointer; margin-left: 10px"
            >
              View
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!requisitionList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import handleRequisitions from "@/services/modules/procurement/requisition";
import {inject, ref}      from "vue";

const route = useRoute();
const router = useRouter();
const props  = defineProps({
  requisitionList: Object,
  status: {
    type: String,
    default: null
  },
  approvalPageId: {
    type: String,
    default: null
  },
  approvalStatusList: {
      type: Array,
      default: []
  },
});
const saveButtonLoader = ref(false)
const $emit = defineEmits(['statusUpdated']);
const showError = inject('showError')
const showSuccess = inject('showSuccess')

const goToReview = (id, viewOnly = false) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let routeName = 'requisition-review';
  if(viewOnly) {
    routeName = 'requisition-view'
  }

  let query = route.query;
  query.requisitionId = id;
  query.approval_page_id = props.approvalPageId;

  router.push({
    name: routeName,
    params: param,
    query: query
  })
}

const getStatus = (statusSlug) => {
  const status = props.approvalStatusList.find(approvalStatus => approvalStatus.id === statusSlug)
  if(status) {
    return status.name;
  }

  return statusSlug
}

</script>
